<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section class="row justify-end">
        <q-input
          outlined
          label="Cari Siswa"
          v-model="searchTerm"
          style="max-width:300px"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section horizontal>
        <q-card-section
          class="column q-gutter-md q-pt-none justify-between"
          style="min-width:300px"
        >
          <q-select
            outlined
            v-model="selKelas"
            label="Kelas"
            :options="kelas"
            @input="(siswa = []), getSiswaList()"
          />

          <q-btn
            filled
            color="primary"
            label="SIMPAN NILAI"
            @click="simpan"
          ></q-btn>
        </q-card-section>
        <q-markup-table
          flat
          bordered
          wrap-cells
          separator="cell"
          class="stickyTable bg-indigo-1 full-width"
          style="height: calc(100vh - 267px);"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th rowspan="2">NISN</th>
              <th rowspan="2">Nama</th>
              <th rowspan="2">Rerata Nilai Sikap Mapel</th>
              <th colspan="2">Nilai Sosial</th>
              <th rowspan="2">Nilai Spiritual</th>
            </tr>
            <tr>
              <th>Dari Walas</th>
              <th>Dari Pengasuhan</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="selKelas == ''">
              <td class="text-center" colspan="6">
                Silahkan Pilih kelas terlebih dahulu
              </td>
            </tr>
            <tr v-else-if="selKelas != '' && siswa.length == 0">
              <td class="text-center" colspan="6">
                Tidak Ada Siswa di kelas terpilih
              </td>
            </tr>
            <tr v-for="(val, i) in filSiswa" :key="i">
              <td>{{ val.nisn }}</td>
              <td>{{ val.nama }}</td>
              <td>
                {blm ada}
              </td>
              <template>
                <q-td>
                  {{ nilai }}
                  <q-popup-edit :value="nilai" title="Masukan Nilai">
                    <q-input type="number" v-model="nilai" dense autofocus />
                  </q-popup-edit>
                </q-td>
                <q-td>
                  {{ nilai }}
                  <q-popup-edit :value="nilai" title="Masukan Nilai">
                    <q-input type="number" v-model="nilai" dense autofocus />
                  </q-popup-edit>
                </q-td>
                <q-td>
                  {{ nilai }}
                  <q-popup-edit :value="nilai" title="Masukan Nilai">
                    <q-input type="number" v-model="nilai" dense autofocus />
                  </q-popup-edit>
                </q-td>
              </template>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import jsPDF from "jspdf";
export default {
  data() {
    return {
      searchTerm: "",
      nilai: 0,
      confirm: false,
      kelas: [],
      selKelas: "",
      siswa: []
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter(val => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    }
  },
  async mounted() {
    await this.getListKelas();
  },
  methods: {
    simpan() {},
    inputsemua() {
      this.siswa.forEach(element => {
        element.catatan = this.catatan;
      });
    },
    async getSikapMapel() {
      let tempSiswa = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/penilaian/sikap/getidnilaiakademik`,
        this.siswa
      );
      for (let i in this.siswa) {
        tempSiswa[i].listNilai = resp.data[i];
      }

      let resp2 = await this.$http.post(
        `/hasil/rekap_nilai/getkehadiran`,
        this.siswa
      );
      for (let i in resp.data) {
        // loop siswa
        let semua = parseFloat(resp2.data[i].semua);
        let hadir = parseFloat(resp2.data[i].hadir);
        tempSiswa[i].kehadiran = parseFloat(
          100 * parseFloat(hadir / semua)
        ).toFixed(2);
      }

      for (let item of tempSiswa) {
      }

      this.siswa = tempSiswa;
      console.log(this.siswa);
    },
    async getListKelas() {
      await this.$http
        .get("/penilaian/catatan/getkelas") //harusnya ditambah id wali kelas
        .then(resp => {
          this.kelas = resp.data;
        });
    },
    async getSiswaList() {
      this.$q.loading.show();
      let resp = await this.$http.get(
        "/penilaian/catatan/getsiswa/" + this.selKelas.value
      );

      resp.data.forEach(element => {
        element.catatan = null;
      });
      this.siswa = resp.data;
      await this.getSikapMapel();
      this.$q.loading.hide();
    }
  }
};
</script>

<style lang="scss" scoped></style>
